import Q1 from '../videos/Feeling.mp4';
import Q2 from '../videos/Motivated.mp4';
import Q3 from '../videos/Water.mp4';
import Q4 from '../videos/Nature.mp4';
import Q5 from '../videos/Reachout.mp4';
import Q6 from '../videos/Help.mp4';
import Q7 from '../videos/Organised.mp4';
import Q8 from '../videos/Goals.mp4';
import Q9 from '../videos/Positive.mp4';
import Q10 from '../videos/Routine.mp4';
import Q11 from '../videos/Sleep.mp4';

/*
Returns a specified public static video based on which question the user is on.
 */
function Video(number) {
    let video;

    switch (number) {
        case parseInt('1'):
            video = Q1;
            break;
        case parseInt('2'):
            video = Q2;
            break;
        case parseInt('3'):
            video = Q3;
            break;
        case parseInt('4'):
            video = Q4;
            break;
        case parseInt('5'):
            video = Q5;
            break;
        case parseInt('6'):
            video = Q6;
            break;
        case parseInt('7'):
            video = Q7;
            break;
        case parseInt('8'):
            video = Q8;
            break;
        case parseInt('9'):
            video = Q9;
            break;
        case parseInt('10'):
            video = Q10;
            break;
        case parseInt('11'):
            video = Q11;
            break;
        default:
            video = Q1;
    }

    return video;
}

export default Video;