// The tools to make this app work.
import React, { useState, useEffect, useRef } from "react";

// Font Awesome Icons called from the node_modules library folder.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowRight, faChevronLeft, faSync} from "@fortawesome/free-solid-svg-icons";

// All Library and Styling Files
import "./sass/app.scss";
import JsonDataEnglish from './json/questions.json'; // All the questions, answers and recommendations are in this file.
import JsonDataWelsh from './json/questions-welsh.json';
import Logo from './images/white-logo.svg'; // Loading a Public Static version of the logo.
import Video from "./components/video";
import {Modal} from "react-bootstrap"; // Loads all the videos depending on which question the user is on. A simple switch function exists in this.
import englishPDF from '../src/Platfform_English_PDF_Proof_v3.pdf';
import welshPDF from '../src/Platfform_Welsh_PDF_Proof_v4.pdf';

// The function to make the Quiz App work.
function App() {

    const search = window.location.search;
    var thisLang = 'en';
    var isLang = JsonDataEnglish;
    if(search){
        const params = new URLSearchParams(search);
        thisLang = params.get('lang');
        isLang = thisLang !== '' ? thisLang:'en';
        console.log(isLang);
    }
    const WhatLang = thisLang === 'en' ? JsonDataEnglish:JsonDataWelsh;


    const [answer, setAnswer] = useState(null); // A state to store all the answers that the user has submitted.
    const [qnumber, setQnumber] = useState(1); // A state to determine which question the user is currently on.
    const [feedback, setFeedback] = useState(null); // A state to store the feedback based on the answer the user selected.
    const [responded, setResponded] = useState(false); // A state to determine whether the user has responded to the question or not. This determines what the user sees next.
    const [recommended, setRecommended] = useState(false); // A state to determine whether the user has clicked the Recommendations button. If so the recommendations modal will appear.
    const [final, setFinal] = useState(false); // A state to determine whether this is/was the final question.
    const [language, setLang] = useState(thisLang); // A state to determine whether this is/was the final question.
    const [JsonData, setQuestions] = useState(WhatLang);

    let jsonData;

    console.log(JsonData);
    // Loops through the JsonData from Line 11 to find out which question the user is on and then add all json data elements to the jsonData variable to display questions, answers, etc.
    // eslint-disable-next-line
    JsonData.map((element) => {
            if(element.number === qnumber) {
                 jsonData = element;
            }
    });    

    useEffect(() => {
        //console.log(JsonData);
        console.log(isLang);
    });

    useRef(()=>{
        console.log(language);
        if(language ==='en'){
            setQuestions(JsonDataWelsh);
            console.log('This should switch to Welsh Data');
            thisLang = 'en';
            console.log(JsonData);
        }else{
            setQuestions(JsonDataEnglish);
            console.log('This should switch to English Data');
            thisLang='cy';
            console.log(JsonData);
        }
    });

    // A function used when the user selects an answer as a choice.
    function handleChoice(e) {
        // ADD ANSWER TO THE ANSWER STATE
        let array;
        if(answer === null) {
            array = [];
        } else {
            array = answer;
        }
        array.push({
            "question": qnumber,
            "answer": e.target.dataset.value.toString()
        })

        // Set an array of answers to the answer state. This will be used to build the PDF later.
        setAnswer(array);

        // Add feedback to the variable so that we can show it once the user has selected an answer. This is quick feedback, that will be reset when the next question button is clicked.
        // That then allows the feedback state to be reset for the next question.
        let feedbackData = [];

        // eslint-disable-next-line
        JsonData.map((element) => {
            if(element.number === qnumber) {
                feedbackData['answer'] = e.target.dataset.value.toString();

                // eslint-disable-next-line
                element.answers.map((elem) => {
                    if( elem.option === e.target.dataset.value.toString()) {
                        feedbackData['feedback'] = elem.feedback;
                        feedbackData['recommendation'] = elem.recommendation;
                    }
                })
            }
        });

        setFeedback(feedbackData);

        // Set response state to true so that the feedback stored can be displayed on the screen. Check Line ...
        setResponded(true);
    }

    // Once the user clicks the button for the next question, this function is called to reset the feedback and responded states.
    // It also loads the next question by adding 1 to the qnumber state and then setting that as the qnumber state.
    function handleQuestion() {
        // Set feedback to null, ready for the next response
        setFeedback(null);

        // Set Question Number to the next Question.
        setQnumber(qnumber + 1);

        // Set responded back to false so that the next question is displayed.
        setResponded(false);

        // Close the Modal if it's open
        setRecommended(false);
    }

    // This function restarts the quiz and sets all states back to their original values.
    function handleRestart() {
        setAnswer(null);
        setFeedback(null);
        setQnumber(1);
        setRecommended(false);
        setResponded(false);
        setFinal(false);
    }

    // This function is launched on the last question to show the last message and the download button for the PDF.
    function handleComplete() {
        setRecommended(false);
        setFinal(true);
    }

    // This function loads the recommendation modal so the user can see what the best recommendations are for the
    // question they just answered.
    const handleModalOpen = () => setRecommended(true);


    // This function closes the recommendation modal so that the user can continue to the next question.
    const handleModalClose = () => setRecommended(false);

    // Go back to the website
    function handleGoBack() {
        window.location.href = "https://projectme.platfform4yp.org";

        /*if (typeof window !== "undefined") {
            return window.history.back();
        }*/
    }

    // Go back to the website
    function switchLang() {
        //window.location.search = '';
        console.log('LANGUAGE: ',language);
        if(language === 'en'){
            setLang('cy');
            setQuestions(JsonDataWelsh);
            console.log(language);
            console.log(JsonData);
            }
        else if(language === 'cy'){
            setLang('en');
            setQuestions(JsonDataEnglish);
            console.log(language);
            console.log(JsonData);
        }
        else{
            setLang('en');
            setQuestions(JsonData);
        }
    }


    return final === false ? (
        <>
            {/* If you want to revert back to the Lottie Animations, uncomment Lines 106 to 108 and comment out Line 109 */}
            {/*<section className={'app vw-100 vh-100'} style={{*/}
            {/*    backgroundImage: 'url(' + Backgrounds(qnumber) + ')'*/}
            {/*}}>*/}
            <section className={'app position-relative'}>
                <div className={'app--video-overlay'} />
                <video key={Video(qnumber)} autoPlay muted playsInline={true} loop id="backgroundVideo" className={'video d-none d-xl-block order-1'}>
                    <source src={Video(qnumber)} type={'video/mp4'} /> {/* Load the video for the question using qnumber as the state from the Video component */}
                </video>
                <button type={'button'} onClick={() => handleGoBack()} className={'app--back-button'}>
                    <FontAwesomeIcon icon={faChevronLeft} /><span class="white">{language === 'en'? 'Back to website':'Nol i\'r wefan'}</span>
                </button>

                <button type={'button'} onClick={() => switchLang()} className={'app--switch-lang'}>
                    {language === 'en' ? 'CY' : 'EN'}
                </button>

                {/* If you want to revert back to the Lottie Animations, uncomment Lines 114 to 116 and comment out Lines 110 to 112 (above) */}
                {/*<aside className={'app--animations app--animations__' + qnumber}>*/}
                {/*    <Animations number={qnumber} />*/}
                {/*</aside>*/}
                <nav className={'nav py-3 order-0'}>
                    <div className={'container-fluid'}>
                        <div className={'row'}>
                            <div className={'col-12 text-center'}>
                                <img src={Logo} alt={'Platfform 4YP'} />
                            </div>
                        </div>
                    </div>
                </nav>
                <div className={'container-fluid app--container'}>
                    <div className={'row app--row'}>
                        {/* As the video isn't mobile responsive, it needs to be loaded here again as not a background */}
                        <div className={'col-12 p-0 d-xl-none position-relative'}>
                            <div className={'app--video-overlay__mobile'} />
                            <video key={Video(qnumber)} autoPlay muted loop playsInline={true} id="backgroundVideoMobile" className={'video'} style={{ display: 'block' }}>
                                <source src={Video(qnumber)} type={'video/mp4'} /> {/* Load the video for the question using qnumber as the state from the Video component */}
                            </video>
                        </div>
                        {/* Question and Feedback Layout */}
                        <div className={'app--question-block col-xxl-4 col-xl-5 col-12'}>
                            <div className={'app--questions h-100'}>
                                <div className={'app--questions--number'}>
                                {language === 'en' ? 'Q' : 'C'}{qnumber}.
                                </div>
                                <div className={'app--questions--question'} dangerouslySetInnerHTML={{__html: jsonData.question}}/>
                                <div className={responded === false ? 'app--options' : 'app--options app--options--responded'}>
                                    {responded === false ?
                                        jsonData.answers.map((element, index) => {
                                            return element.option !== null ?
                                                <div className={'app--options--choose shadow-sm'}
                                                            key={index} data-value={element.option} onClick={handleChoice}>
                                                    <div className={'number'} data-value={element.option}>
                                                        <span data-value={element.option}>{index + 1}</span>
                                                    </div>
                                                    <span className={'label'} data-value={element.option}>{element.option}</span>
                                                </div>
                                            :
                                                <div className={'app--options--choose app--options--choose__disabled shadow-sm'}
                                                     key={index} />
                                        })
                                        :
                                        <div className={'app--response shadow-md'}>
                                            <div className={'app--response__graphic'} />
                                            <div className={'app--response__feedback'}>
                                                <h4>{feedback.answer}</h4>
                                                <p dangerouslySetInnerHTML={{__html:feedback.feedback}}></p>
                                                <p>
                                                    <button type={'button'} onClick={handleModalOpen} className={'app--response--btn app--response--btn__recommendations shadow-sm'}>
                                                    {language === 'en' ? 'Recommendations' : 'Argymhellion'}
                                                    </button>
                                                    <Modal show={recommended} onHide={handleModalClose} size={'lg'} scrollable={true}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>{language === 'en' ? 'Q' : 'C'}{qnumber}. {language === 'en' ? 'Recommendations' : 'Argymhellion'}</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body dangerouslySetInnerHTML={{__html:feedback.recommendation}}>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <button type={'button'} className={'app--response--btn app--response--btn__next shadow-sm position-relative'} onClick={handleModalClose}>
                                                            {language === 'en' ? 'Close' : 'Cau'}
                                                            </button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                    {jsonData.last === false ?
                                                        <button type={'button'} className={'app--response--btn app--response--btn__next shadow-sm'}
                                                                onClick={handleQuestion}>
                                                            {language === 'en' ? 'Q' : 'C'}{qnumber + 1}. <FontAwesomeIcon icon={faArrowRight} />
                                                        </button> : <button type={'button'} className={'app--response--btn app--response--btn__next shadow-sm'}
                                                                            onClick={handleComplete}>
                                                            {language === 'en' ? 'Finish' : 'Gorffen'} <FontAwesomeIcon icon={faArrowRight} />
                                                        </button>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    ) : (
        <>
            {/* Once the final question has been answered, this layout is displayed */}
            <section className={'app position-relative'}>
                <video key={Video(qnumber)} autoPlay muted loop id="backgroundVideo" className={'video d-none d-xl-block order-1'}>
                    <source src={Video(qnumber)} type={'video/mp4'} />
                </video>
                <button type={'button'} onClick={() => handleGoBack()} className={'app--back-button'}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                {/*<aside className={'app--animations app--animations__' + qnumber}>*/}
                {/*    <Animations number={qnumber} />*/}
                {/*</aside>*/}
                <nav className={'nav py-3 order-0'}>
                    <div className={'container-fluid'}>
                        <div className={'row'}>
                            <div className={'col-12 text-center'}>
                                <img src={Logo} alt={'Platfform 4YP'} />
                            </div>
                        </div>
                    </div>
                </nav>
                <div className={'container-fluid app--container'}>
                    <div className={'row h-100'}>
                        <div className={'col-12 p-0 d-xl-none'}>
                            <video key={Video(qnumber)} autoPlay muted loop id="backgroundVideoMobile" className={'video'}>
                                <source src={Video(qnumber)} type={'video/mp4'} />
                            </video>
                        </div>
                        <div className={'app--question-block col-xl-4 col-12'}>
                            <div className={'app--questions h-100'}>
                                <div className={'app--questions--number'}>
                                {language === 'en' ? 'Complete' : 'Wedi\'i gwblhau'}.
                                </div>
                                <div className={'app--questions--question'}>
                                {language === 'en' ? 'Thank you for taking the quiz. Please download your PDF' : 'Diolch am gymryd y cwis. Lawrlwythwch eich argymhellion.'}
                                    
                                </div>
                                <div className={'app--options app--options--responded'}>
                                    <div className={'app--response shadow-md'}>
                                        <div className={'app--response__graphic'} />
                                        <div className={'app--response__feedback'}>
                                            <h4>{language ==='en'? 'Download the PDF':'Lawrlwytho’r PDF.'}</h4>
                                            <p>{language ==='en' ? 'Take a moment to download the PDF which will give you advice on how to feel better and make improvements to your mental health long-term.':'Diolch am gymryd y cwis. Lawrlwythwch eich argymhellion.'}</p>
                                            <p className={'app--response__pdf'}>
                                            {language === 'en' ? <a href={englishPDF} download='Platfform_English_PDF_Proof_v3.pdf'>Download</a>:<a href={welshPDF} download='Platfform_Welsh_PDF_Proof_v4.pdf'>Llawrlwytho</a>}
                                                <button type={'button'} className={'app--response--btn app--response--btn__next shadow-sm'} onClick={handleRestart}>
                                                {language === 'en' ? 'Start Again':'Dechrau eto'} <FontAwesomeIcon icon={faSync} />
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default App;